<template>
  <a-card
    class="card t-l"
    :tab-list="tabListNoTitle"
    :active-tab-key="noTitleKey"
    @tabChange="(key) => onTabChange(key, 'noTitleKey')"
  >
    <a-checkbox class="check-card" />
    <div v-if="noTitleKey === 'product'">
      <div class="product">
        <a-row :gutter="[24, 24]" type="flex">
          <a-col :span="4">
            <div class="product-images">
              <img
                class="product-imagei"
                :src="item.image"
                alt=""
                srcset=""
              >
            </div>
          </a-col>
          <a-col :span="16">
            <div>标题:</div>
            <div class="product-title">
              {{ item.subject }}
            </div>

            <div class="product-price">
              <div>价格:</div>
              <div>¥ {{ item.price }}</div>
            </div>

            <div class="product-price">
              <div>规格:</div>
              <div>{{ item.attribute }}</div>
            </div>
          </a-col>
          <a-col :span="4">
            <div class="operation">
              <div class="operation-label">{{ item.status | statusFilter }}</div>
              <el-button v-show="item.status === '0'" type="primary" class="operation-btn" @click="toBuyOrder(item)">
                支付
              </el-button>
            </div>
          </a-col>
        </a-row>
      </div>
    </div>
    <div v-else-if="noTitleKey === 'detail'" class="content" style="max-height:300px;overflow-y: scroll;">
      <div v-html="item.describe" />
    </div>
    <div v-else-if="noTitleKey === 'sku'" class="content">
      {{ item.attribute }}
      <!-- <alibabaTable :sku-list-data="item.skuList" /> -->
    </div>
    <div v-else-if="noTitleKey === 'pic'" class="content">
      <img
        style="width:150px;height:150px"
        :src="item.image"
        alt=""
        srcset=""
      >
    </div>
    <a-button slot="tabBarExtraContent" type="link" @click="toDetail(item)">
      详情
    </a-button>
  </a-card>
</template>

<script>
// import alibabaTable from '../selectionlistcomponent/alibabaTable'
const statusMap = {
  0: {
    text: '待支付'
  },
  1: {
    text: '待发货'
  },
  2: {
    text: '待收货'
  },
  3: {
    text: '确认收货/已完成'
  },
  5: {
    text: '已关闭'
  }
}
export default {
  components: {
    // alibabaTable
  },
  filters: {
    statusFilter(type) {
      return statusMap[type].text
    }
  },
  props: {
    item: {
      type: Object,
      default: function() {
        return {}
      }
    }
  },
  data() {
    return {
      key: 'tab1',
      noTitleKey: 'product',
      tabListNoTitle: [
        {
          key: 'product',
          tab: '商品'
        },
        {
          key: 'detail',
          tab: '商品描述'
        },
        {
          key: 'sku',
          tab: '商品规格'
        },
        {
          key: 'pic',
          tab: '主要图片'
        }
      ]
    }
  },
  methods: {
    onTabChange(key, type) {
      console.log(key, type)
      this[type] = key
    },
    toDetail(item) {
      if (!item.productId) {
        return
      }
      this.$router.push({ path: '/detailproduct/index', query: { productId: item.productId, selectType: item.supplyType, product: item.price }})
    },
    toBuyOrder(item) {
      if (!this.item.productId) {
        this.$message.error('缺少参数，请选择商品')
        return
      }
      this.$router.push({ path: '/detailproductorder/index', query: { productId: item.productId, selectType: item.supplyType }})
    }
  }
}
</script>

<style lang="scss" scoped>
  .t-l {
  text-align: left;
 }
 .card {
  position: relative;
  border-radius: 4px;
  margin-bottom: 20px;
}
 .check-card {
  position: absolute;
  left: 20px;
  top: 17px;
}
.product {
  // display: flex;
  &-images {
    padding: 0 10px;
    background-color: #fff;
    // width: 180px;
    // height: 180px;
    position: relative;
  }
  &-imagei {
    object-fit: contain;
    object-position: center;
    width: 100%;
    height: 100%;
    z-index: 100;
  }

  &-title {
    font-weight: 600;
    color: rgb(36, 40, 51);
  }
  &-price{
    margin-top: 20px;
  }
}

.content{
  padding: 10px;
}

.operation{
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  .operation-label{
    text-align: right;
    flex: 1;
  }
  .operation-btn{
    max-width: 200px;
    align-self: end;
  }
}
</style>
