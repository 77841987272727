<template>
  <div style="min-height: calc(100vh - 93px); background-color: #eaedf1; padding: 1.25vw">
    <div class="top">
      <div class="title">
        订单列表
      </div>
      <!-- <div class="search">
        <el-select
          v-model="value"
          size="small"
          placeholder="请选择"
          style="margin-right: 20px"
        >
          <el-option
            v-for="item in alibabaCategoryList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
        <a-input-search placeholder="搜素商品" @search="onSearch" />
      </div> -->
    </div>

    <div v-loading="loading">
      <div>
        <div class="t-l" :style="{ borderBottom: '1px solid #E9E9E9' }">
          <a-checkbox
            :indeterminate="indeterminate"
            :checked="checkAll"
            @change="onCheckAllChange"
          >
            选择
          </a-checkbox>
        </div>
        <br>
      </div>
      <!-- 卡片 -->
      <purchasedCard
        v-for="(item, index) in orderList"
        :key="index"
        :item="item"
      />
    </div>
    <el-pagination
      class="fenye"
      background
      :current-page="page.current"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="page.size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="page.total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </div>
</template>

<script>
import { getOrderList } from '@/api/register'
import purchasedCard from './purchasedCard'
import Pagination from '../selectionlistcomponent/pagination'
export default {
  components: {
    purchasedCard
  },
  mixins: [Pagination],
  data() {
    return {
      value: '1',
      alibabaCategoryList: [
        {
          label: '全部',
          value: '1'
        }
      ],

      orderList: [],

      checkedList: ['Apple', 'Pear', 'Orange'],
      indeterminate: true,
      checkAll: false,
      plainOptions: ['Apple', 'Pear', 'Orange'],

      key: 'tab1',
      noTitleKey: 'product',
      tabListNoTitle: [
        {
          key: 'product',
          tab: '商品'
        },
        {
          key: 'detail',
          tab: '商品描述'
        },
        {
          key: 'sku',
          tab: '商品规格'
        },
        {
          key: 'pic',
          tab: '主要图片'
        }
      ]
    }
  },
  created() {
    this.getList()
  },
  methods: {
    onSearch() {},
    onChange(checkedList) {
      this.indeterminate =
        !!checkedList.length && checkedList.length < this.plainOptions.length
      this.checkAll = checkedList.length === this.plainOptions.length
    },
    onCheckAllChange(e) {
      Object.assign(this, {
        checkedList: e.target.checked ? this.plainOptions : [],
        indeterminate: false,
        checkAll: e.target.checked
      })
    },
    onTabChange(key, type) {
      console.log(key, type)
      this[type] = key
    },
    getList() {
      this.loading = true
      getOrderList(this.page)
        .then((result) => {
          if (result.code === 0) {
            this.loading = false
            this.orderList = result.data.records
            this.page.total = result.data.total
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}
.title {
  text-align: left;
  font-size: 28px;
  font-weight: 700;
  color: #363d4d;
}
.search {
  display: flex;
  align-items: center;
}

.t-l {
  text-align: left;
}

.fenye {
  display: flex;
  justify-content: end;
  margin-top: 10px;
}

::v-deep .ant-tabs-nav-wrap {
  margin-left: 20px;
}
</style>
